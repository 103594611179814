<template>
  <div class="summarize">
    <div class="summarize-header">
      <span class="title">客户群概览</span>
      <!-- <span class="point">数据截止时间：{{ lastTime }}</span> -->
    </div>
    <div class="summarize-subtitle">
      <span>共{{ enterNum }}个客户入群</span>
    </div>
    <div class="summarize-row">
      <div class="summarize-item">
        <div class="num">{{ groupTotal }}</div>
        <div class="label">总群数</div>
      </div>
      <div class="summarize-item">
        <div class="num">{{ customerTotal }}</div>
        <div class="label">总客户数</div>
      </div>
      <div class="summarize-item">
        <div class="num">{{ messageTotal }}</div>
        <div class="label">昨日总消息数</div>
      </div>
    </div>
    <div class="summarize-row">
      <div class="summarize-item">
        <div class="num">{{ addGroup }}</div>
        <div class="label">今日新增群数</div>
      </div>
      <div class="summarize-item">
        <div class="num">{{ addCustomer }}</div>
        <div class="label">今日群聊新增客户数</div>
      </div>
      <div class="summarize-item">
        <div class="num">{{ sendMessage }}</div>
        <div class="label">昨日发过消息的客户数</div>
      </div>
    </div>
  </div>
</template>

<script>
import { Notify } from 'vant'
import { statisticsGroups } from '../../service/group.service'

export default {
  data() {
    return {
      enterNum: null,
      addNum: null,
      groupTotal: null,
      customerTotal: null,
      messageTotal: null,
      addGroup: null,
      addCustomer: null,
      sendMessage: null,
      lastTime: null
    }
  },
  created() {
    this.initData()
  },
  methods: {
    async initData() {
      try {
        const data = await statisticsGroups()
        this.enterNum = data.enterNum
        this.addNum = data.addNum
        this.groupTotal = data.groupTotal
        this.customerTotal = data.customerTotal
        this.messageTotal = data.messageTotal
        this.addGroup = data.addGroup
        this.addCustomer = data.addCustomer
        this.sendMessage = data.sendMessage
        this.lastTime = data.lastTime
        this.$nextTick(() => {
          console.log('mounted')
          document.getElementById('content').scrollTop = 0
        })
      } catch (e) {
        Notify({ type: 'danger', message: '客户群预览数据获取失败！' })
      }
    }
  }
}
</script>

<style lang="less" scoped>
@import '../../assets/style/theme.less';

.summarize {
  margin: 8px;
  font-size: 14px;
  border-radius: 5px;
  background: @white;
  &-header {
    display: flex;
    justify-content: space-between;
    padding: 12px;
    .title {
      font-weight: bold;
      border-bottom: 4px solid #1989fa;
    }
    .point {
      color: @gray-6;
    }
  }

  &-subtitle {
    margin-left: 12px;
    color: @blue;
  }

  &-row {
    display: flex;
    padding: 12px 0;
    border-bottom: 1px solid #eaeaea;
    &:last-of-type {
      border-bottom: none;
    }
  }

  &-item {
    flex: 1;
    text-align: center;

    > .label {
      padding-top: 6px;
      color: @gray-6;
      font-size: 12px;
    }
  }
}
</style>
