<template>
  <div class="wrapper">
    <div class="wrapper-item" @click="showSort = true">
      <span>排序</span>
      <van-icon name="sort" />
    </div>
    <div class="wrapper-item" @click="showSearch = !showSearch">
      <span>搜索</span>
      <van-icon name="search" />
    </div>
  </div>
  <!-- 搜索框 -->
  <van-search
    v-show="showSearch"
    v-model="query"
    placeholder="请输入搜索关键词"
    @search="$emit('search', $event)"
  />
  <!-- 排序 -->
  <van-overlay :show="showSort" z-index="99">
    <div class="inner-page">
      <!-- 排序方式 -->
      <filter-item
        v-model:value="order"
        title="排序方式"
        :options="opt.order"
      />
      <!-- 排序属性 -->
      <filter-item
        v-model:value="orderType"
        title="排序属性"
        :options="opt.oredrType"
      />
      <!-- 底部按钮 -->
      <div class="inner-page-button">
        <van-button plain hairline square @click="handleReset">重置</van-button>
        <van-button type="primary" square @click="handleConfirm">
          确定
        </van-button>
      </div>
    </div>
  </van-overlay>
</template>

<script>
import { Icon, Overlay, Button, Search, Notify } from 'vant'
import FilterItem from './_filter-item.vue'
import { getLabel } from '../../service/group.service'

export default {
  components: {
    FilterItem,
    [Icon.name]: Icon,
    [Overlay.name]: Overlay,
    [Button.name]: Button,
    [Search.name]: Search
  },
  emits: ['search', 'sort'],
  data() {
    return {
      showSort: false,
      showSearch: false,
      query: '',
      order: 1,
      orderType: 1,
      opt: {
        order: [],
        oredrType: []
      }
    }
  },
  created() {
    this.initFilterLabel()
  },
  methods: {
    // 初始化筛选标签
    async initFilterLabel() {
      try {
        const data = await getLabel()
        this.order = data.orderDef
        this.orderType = data.orderTypeDef
        this.opt.order = data.order
        this.opt.oredrType = data.orderType
        // 保存默认参数
        this.__orderDef = data.orderDef
        this.__orderTypeDef = data.orderTypeDef
      } catch (e) {
        Notify({ type: 'danger', message: '排序标签数据获取失败！' })
      }
    },
    // 重置为默认条件
    handleReset() {
      this.order = this.__orderDef
      this.orderType = this.__orderTypeDef
    },
    // 确定筛选条件
    handleConfirm() {
      this.showSort = false
      this.$emit('sort', { order: this.order, orderType: this.orderType })
    }
  },
  watch: {
    showSearch(val) {
      if (!val) {
        this.query = ''
        this.$emit('search', '')
      }
    }
  }
}
</script>

<style lang="less" scoped>
@import '../../assets/style/theme.less';

.wrapper {
  display: flex;
  background: @white;
  height: 36px;
  line-height: 36px;
  font-size: 14px;
  color: @gray-8;

  &-item {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
  }
}

.inner-page {
  width: 100%;
  height: 100%;
  background: @white;

  &-close {
    position: absolute;
    top: 10px;
    right: 10px;
    padding: 10px;
    font-size: 20px;
  }

  &-button {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    > .van-button {
      flex: 1;
    }
  }
}
</style>
